
import { Component, Vue } from "vue-property-decorator";
import { LOADER_TITLE } from "../constants/constants";
@Component
export default class Loader extends Vue {
  public loaderTitles = LOADER_TITLE;
  public loaderTitleId = null;
  public get tmpcoLoader(): any {
    this.loaderTitleId = this.$store.state.loaderTitleId;
    return this.$store.state.tmpcoLoader;
  }
}
