var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c('vue-snotify'),(_vm.userType)?_c('div',[_c('Header')],1):_vm._e(),(_vm.userType == 1)?_c('div',[_c('SideNav',{attrs:{"isHide":_vm.isHide}})],1):_vm._e(),_c('div',[_c('Footer')],1),_c('div',{class:_vm.userType == 1
        ? _vm.isHide
          ? 'sidebar-slide'
          : ''
        : _vm.$route.name == 'SignUp'
        ? 'overflow-signup sidebar-slide'
        : 'sidebar-slide',attrs:{"id":"wrapper"}},[_c('div',{attrs:{"id":_vm.userType == 1 ? 'page-content-wrapper' : ''}},[_c('router-view')],1)]),_c('Loader')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }