
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "../../config";
import Axios from "axios";
import { authHeader } from "../../services/auth";
import Header from "../Header.vue";
import { CompanyInfo } from "../../models/super-admin/company-details.model";
import VueSignaturePad  from 'vue-signature-pad';

//import component from "*.vue";

@Component({ components: { Header, VueSignaturePad} 

})
export default class MyAccount extends Vue {
  /* eslint-disable */
  public obj: any = {};
  public isShow = true;
  // public profilePic = null;
  public profileImage = null;
  public proImage = null;
  public userData: any = {};
  public processorInfo: any = {};
  public BrokerInfo: any = {};
  public userDetails = {};
  public raw: any;
  public passwordFieldType = 'password'
  public conPasswordFieldType = 'password'
  public currentPassFieldType = 'password'
  public companyInfo: any = new CompanyInfo();

  public agentCompanyInfo = {}
  public agentType = ''
  public  options = {
      // penColor: "#c0f"
  }
  public  undo() {
      this.$refs.signaturePad['undoSignature']();
    }
    
  public async saveSignature() {
    try {
      const signature = this.$refs.signaturePad['saveSignature']();
      const response = await Axios.post(
        BASE_API_URL + "common/my-account/saveSignature",
        signature,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success("Saved Successfully");
        this.$modal.hide("addSignature");
        this.getUserProfileinfo()
      } 
    } catch (error) {
      console.log(error);
    }
  }

  


  public async changePassword() {
    try {
      if (this.obj.confirmPassword !== this.obj.newPassword) {
        return;
      }
      const response = await Axios.post(
        BASE_API_URL + "common/changePassword",
        this.obj,
        { headers: authHeader() }
      );
      if (response.data.status == "error") {
        this.$snotify.error(response.data.message);
        // await this.$store.dispatch("setSessionData", response.data);
      } else {
        this.$snotify.success(response.data.message);
        this.obj = {};
        this.$refs.observer["reset"]();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async uploadFile(event) {
    try {
      this.$store.state.tmpcoLoader = true;
      if (event.target.files.length <= 0) return;
      const formData: any = new FormData();
      Array.from(event.target.files).forEach((element) => {
        formData.append("file", element);
      });
      formData.append("userId", this.$store.state.sessionObj.userId);
      formData.append("userType", this.$store.state.sessionObj.userType);
      const response = await Axios.post(
        BASE_API_URL + "common/my-account/uploadProfilePicture",
        formData,
        { headers: authHeader() }
      );
      if(response.status == 201)
      if (this.$store.state.sessionObj.userType === 3) {
        this.fetchProcessorData();
        await this.getUserProfileinfo();
        this.getprofileImage();
        EventBus.$emit('get-profile-pic');
        this.$store.state.tmpcoLoader = false;
      }
    } catch (error) {
      this.$store.state.tmpcoLoader = false;
      console.log(error);
    }
  }

  public loadImage(path) {
    try {
      setTimeout(function () {
        const dom:any = document.getElementById("imageEl");
        const img:any = document.createElement("img");
        img.src = path;
        img.id = "img-id";
        if(img.height > img.width){
          img.style.height = "100%";
          img.style.width = "auto"
          dom.style.height = "100%";
          dom.style.width = "auto"
        }
        if (img.height < img.width){
          img.style.height = "auto";
          img.style.width = "100%" 
          dom.style.height = "auto";
          dom.style.width = "100%"          
        }

        if (dom) {
          dom.innerHTML = "";
          dom.appendChild(img);
        }
      }, 10);
    } catch (error) {
      console.log(error);
    }
  }
  public async fetchProcessorData() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/my-account/processorDetails",
        { headers: authHeader() }
      );

      if (response.data.processorData.profileImage) {
        this.profileImage = response.data.processorData.profileImage;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async getprofileImage() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.post(
        `${BASE_API_URL}common/my-account/getprofileImage`,
        { userId: this.$store.state.sessionObj.userId },
        { headers: authHeader() }
      );

      if (response.data.profileImageData.profileImage) {
        this.proImage = response.data.profileImageData.profileImage;
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }


  public async getUserProfileinfo() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}common/my-account/getUserProfileinfo`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.userDetails = response.data.userinfo
          ? response.data.userinfo
          : response.data;

        console.log('RESPONSE ---',this.userDetails)
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async userValidate() {
    const res = await Axios.get(
      BASE_API_URL + "common/my-account/userValidate",
      {
        headers: authHeader(),
      }
    );
    if (res.status === 201) {
      this.userData = res.data ? res.data : null;
    }
    console.log('userType---',this.userData)
  }
  
    public async getCompanyDetail() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/company-details/getCompanyDetails`,
        {
          headers: authHeader(),
        }
      );
      if (response.data.length > 0) this.companyInfo = response.data[0];
      
    } catch (error) {
      console.log(error);
    }
  }

  public switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    }
  public switchConPassVisibility() {
      this.conPasswordFieldType = this.conPasswordFieldType === 'password' ? 'text' : 'password'
    }

  public currentPassVisibility() {
      this.currentPassFieldType = this.currentPassFieldType === 'password' ? 'text' : 'password'
    }

    public openSignatureModal() {
    try {
      this.$modal.show("addSignature");
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.$modal.hide("addSignature");   
  }

  public async getAgentCompanyInfo(){
    try{
      const response = await Axios.get(
        `${BASE_API_URL}agent/getAgentCompanyInfo`, { headers: authHeader() } );

      if (response.status === 201) {
        this.agentCompanyInfo = response.data
          ? response.data.data
          : response.data;
      }
    } catch(error){
      console.log(error);
    }
  }

  public async getCurrentlyLoggedAgentType(){
    try{
        const response = await Axios.get(
        `${BASE_API_URL}agent/getCurrentlyLoggedAgentType`, { headers: authHeader() } );

      if (response.status === 201) {
        this.agentType = response.data.data.name
      }
      console.log('agent type',response.data.data.name)
    }catch(error){
        console.log(error);
    }
  }

  async mounted() {
    if (this.$store.state.sessionObj.userType == 3) await this.fetchProcessorData();
    if (this.$store.state.sessionObj.userType == 6) {
      await this.getAgentCompanyInfo();
      await this.getCurrentlyLoggedAgentType();
      }
    await this.userValidate();
     await this.getUserProfileinfo();
    await this.getCompanyDetail()
  }
  /* eslint-disable */
}
