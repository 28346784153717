
import { Component, Vue } from "vue-property-decorator";
import Header from "./common-components/Header.vue";
import Footer from "./common-components/Footer.vue";
import SideNav from "./common-components/SideNav.vue";
import Loader from "./common-components/Loader.vue";
import { EventBus } from "./config";

@Component({
  components: {
    Header,
    Footer,
    SideNav,
    Loader,
  },
})
export default class AppComponent extends Vue {
  public isHide = false;

  public get userType() {
    return this.$store.state.sessionObj.userType;
  }

  public showHide(data) {
    this.isHide = data;
  }

  mounted() {
    console.log(this.$route.name,'this.$route.name');
    
    if (
      this.$route.name == "Mortgage Loan Conditions" &&
      this.$store.state.sessionObj.userType == 2
    ) {
      this.$router.push({
        path: "/loan-process",
        query: {
          id: this.$route.query.id,
          conditionDoc: "Yes",
        },
      });
    } else if (
      this.$route.name == "Mortgage Loan Conditions" &&
      this.$store.state.sessionObj.userType == 4
    ) {
      this.$router.push({
        path: "/borrower-dashboard",
        query: {
          id: this.$route.query.id,
          conditionDoc: "Yes",
        },
      });
    }
    this.$store.state.tmpcoLoader = false;
    this.$store.state.sessionObj.token === null ? this.$router.push("/") : null;
    EventBus.$on("show-hide", (data) => {
      this.isHide = data;
    });
  }
}
